@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,600i');


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$grey-lighter: 					#f2f2f2;
$grey-light: 						#e1e3e5;
$grey: 									#66707a;
$grey-dark: 						#444444;

$orange: 								#f03c02;
$red:				 						#CC0000;
$green: 								#96c93d;
$blue: 									#255691;
$teal: 									#5adb97;

$color-pri:         		$teal;


// Font family
$font-pri:							'Montserrat', sans-serif;