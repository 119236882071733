html {
	font-size: 10px;
	line-height: 1.3em;
	scroll-behavior: smooth;
}

body {
	font-family: $font-pri;
	background: white;
	position: relative;
	color: $grey-dark;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

.title {
	font-size: 4rem;
	font-weight: 300;
	@include hr($color-pri, 'center', 'bottom');

	@include media-breakpoint-down(lg) {
		font-size: 3.5rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 3rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 2.5rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 2rem;
	}

	&.title-bold {
		font-weight: bold;
		margin-top: .5em;
	}
}

.subtitle {
	font-size: 2.4rem;
	font-weight: 600;
	margin-bottom: 30px;

	@include media-breakpoint-down(lg) {
		font-size: 2.2rem;
		margin-bottom: 27px;
	}

	@include media-breakpoint-down(md) {
		font-size: 2rem;
		margin-bottom: 24px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.8rem;
		margin-bottom: 21px;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.6rem;
		margin-bottom: 18px;
	}

	&.subtitle-light {
		font-weight: 300;
		margin-bottom: .75em;
	}
}

.small-title {
	font-size: 1.4rem;
	color: $grey;
	font-weight: bold;
	text-transform: uppercase;

	@include media-breakpoint-down(md) {
		font-size: 1.3rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.2rem;
	}
}

h2,h3,h4,h5,h6 {
	font-weight: 700;
}

h2:not(:first-child) {
	margin-top: 30px;
}

h3:not(:first-child) {
	margin-top: 25px;
}

h4:not(:first-child) {
	margin-top: 20px;
}

a, p, li, td, th, .text, .text p {
	font-size: 1.8rem;
	font-weight: 300;

	@include media-breakpoint-down(lg) {
		font-size: 1.7rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.6rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.5rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.4rem;
	}
	
	&.text-smaller,
	&.text-small a {
		font-size: 1.5rem;

		@include media-breakpoint-down(lg) {
			font-size: 1.4rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.3rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.2rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.1rem;
		}
	}
	
	&.text-white {
		color: white;
	}
}

strong {
	font-weight: 600;
}

.color-sec {
	color: $color-pri;
}

ul > li {
	list-style: square;
}

ul > li > ul > li {
	list-style: circle;
}

nav {
	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
	}
}

a {
	color: $color-pri;
	transition: all 0.2s ease-in-out;

	&:focus {
	  text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 1px solid $color-pri;
  margin: 20px auto;
  padding: 0; 
}

iframe {
	border: 0;
}

.video-wrapper {
	// margin-top: 30px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.table {
	thead {
		border-top: 0;
		border-bottom: 3px solid $grey-light;

		th {
			border-top: 0;
		}

		td, th {
			font-weight: bold;
			padding: 10px;
		}
	}

	tbody {
		& > tr {
			border-bottom: 1px solid $grey-light;

			& > th,
			& > td {
				border: 0;
				padding: 8px;
			}
		}
	}

	&.table-responsive {
		display: table;

		@include media-breakpoint-down(xs) {
			display: block;
		}
	}
}


.reveal {
	visibility: hidden;
}



















