@mixin gradient($color1, $color2) {
	background-color: $color1;
	background: linear-gradient(to bottom,  $color1 0%,$color2 100%);
}

@mixin gradient-side($color1, $color2) {
	background-color: $color1;
	background: linear-gradient(to right,  $color1 0%,$color2 100%);
}

@mixin section-padding {
	padding: 120px 0;

  @include media-breakpoint-down(lg) {
    padding: 100px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 80px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 60px 0;
  }

  @include media-breakpoint-down(xs) {
    padding: 40px 0;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin hr($color, $x, $y) {
  position: relative;

  @if $y == 'bottom' {
    padding-bottom: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-down(lg) {
      padding-bottom: 35px;
      margin-bottom: 35px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 25px;
      margin-bottom: 25px;
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  } @else {
    padding-top: 40px;
    margin-top: 40px;

    @include media-breakpoint-down(lg) {
      padding-top: 35px;
      margin-top: 35px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 25px;
      margin-top: 25px;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 20px;
      margin-top: 20px;
    }
  }
  
  &:after {
    content: '';
    width: 100px;
    height: 1px;
    background: $color;
    position: absolute;

    @if $x == 'left' {
      left: 0;
    } @else {
      left: 50%;
      transform: translateX(-50%);
    }

    @if $y == 'bottom' {
      bottom: 0;
    } @else {
      top: 0;
    }
  }
}