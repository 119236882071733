// Search form
// .search-form {
//   @extend .form-inline;
// }
// .search-form label {
//   font-weight: normal;
//   @extend .form-group;
// }
// .search-form .search-field {
//   @extend .form-control;
// }
// .search-form .search-submit {
//   @extend .btn;
//   @extend .btn-secondary;
// }

form {
	text-align: left;

	textarea {
		resize: vertical;
	}

	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="tel"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	select,
	textarea {
		font-family: $font-pri;
		font-size: 1.6rem;
		font-weight: 400;
		color: $color-pri;
		width: 100%;
		padding: 8px 12px;
		outline: none;
		transition: all 0.2s ease-in-out;
		border-radius: 0;
		background: $grey-lighter;
		border: 0;
		display: block;

		@include media-breakpoint-down(lg) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.4rem;
		}

		&:focus {
			box-shadow: 0 0 1px $grey;
		}

		@include placeholder {
			font-size: 1.4rem;
			font-weight: 400;
		  color: $color-pri;
		  text-transform: initial;
		}
	}

	textarea {
		width: 100%;
		height: 100px;
	}
}

// /*---------------------------------------------
// ** GRAVITY FORMS
// ---------------------------------------------*/

.gform_body {
	.gform_fields {
		padding: 0;

		&:after {
			content: "";
		  display: table;
		  clear: both;
		}
	}

	.form-col-1,
	.form-col-2 {
		@include media-breakpoint-up(sm) {
			width: 49%;
		}
	}

	.form-col-1 {
		@include media-breakpoint-up(sm) {
			float: left;
		}
	}

	.form-col-2 {
		@include media-breakpoint-up(sm) {
			float: right;
		}
	}

	.clear-float {
		margin: 0 !important;

		&:after {
			content: "";
		  display: table;
		  clear: both;
		}
	}

	.gfield {
		margin: 10px 0;
		list-style: none;
		vertical-align: top;
		padding: 0;

		// &:first-child {
		// 	margin-top: 0;
		// }
	}

	.gfield_label {
		color: $color-pri;
		font-size: 1.4rem;
		font-weight: 700;
		margin: 0;
		text-transform: uppercase;
	}

	.gfield_required {
		color: $red;
		font-size: 1.3rem;
		vertical-align: text-top;
	}

	.gfield_visibility_,
	.instruction,
	.gform_validation_container {
		display: none;
	}

	.ginput_container_select,
	.datepicker .ginput_container_text {
		position: relative;
		cursor: pointer;
		background: $grey-lighter;

		&:before {
			font-family: 'FontAwesome';
			content: '\f078';
			color: black;
			font-size: 1.5rem;
			position: absolute;
			top: 50%;
			right: 10px;
			z-index: 0;
			transform: translateY(-50%);
			cursor: pointer;
		}

		select {
			position: relative;
			z-index: 2;
			appearance: none;
			background: transparent;
			padding-right: 30px;
			cursor: pointer;
			height: auto;
			display: block;
		}
	}

	.arrow-up {
		.ginput_container_select {
			&:before {
				content: '\f061';
				transform: translateY(-50%) rotate(-45deg);
			}
		}
	}

	.arrow-down {
		.ginput_container_select {
			&:before {
				content: '\f061';
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}

	.datepicker {
		.ginput_container_text {

			&:before {
				content: '\f073';
				cursor: default;
				pointer-events: none;
			}
		}
	}
}

.gform_footer {
	text-align: center;

	// .gform_button {
	// 	background: $color-pri;
	// 	border: 0;
	// 	color: white;
	// 	font-size: 2rem;
	// 	padding: 15px 30px;
	// 	margin: 0;
	// }
}

.validation_message {
	color: $red;
	margin-top: 5px;
	font-size: 1.3rem;
	font-weight: bold;
}

.gfield_error {
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	input[type="tel"],
	select,
	textarea {
		border: 1px solid $red !important;
		color: $red !important;
		
		@include placeholder {
		  color: $red !important;
		}
	}
}
.validation_error {
	color: $red;
	font-size: 1.5rem;
	font-weight: bold;
	margin: 10px 0;
}

.gform_confirmation_message {
	color: white;
  background: $green;
  padding: 20px;
  font-size: 1.5rem;
}

.gfield_radio {
	padding: 0;

	li {
		list-style-type: none;
	}

	label {
		position: relative;
		padding-left: 30px;
		cursor: pointer;

		&:before {
		  content: "";
		  display: inline-block;
			cursor: pointer;
		  width: 16px;
		  height: 16px;  
		  margin-right: 10px;
		  position: absolute;
		  left: 0;
		  top: 50%;
		  transform: translateY(-50%);
		  background: transparent;
		  border: 2px solid $grey;
		  border-radius: 50%;
		  z-index: 1;
		}
	}

	input[type="radio"] {
		display: none;

		&:checked {
			&+ label {
				&:after {
				 	content: '';
				  color: black;
				  background: $grey;
				  border-radius: 50%;
				  width: 6px;
				  height: 6px;
				  position: absolute;
				  top: 50%;
				  left: 5px;
				  transform: translateY(-50%);
				  z-index: 2;
				}
			}
		}
	}
}





