.post {
	background: white;
	padding: 30px;
	margin: 15px 0;
	display: block;
	position: relative;

	@include media-breakpoint-down(lg) {
		padding: 25px;
		margin: 13px 0;
	}

	@include media-breakpoint-down(md) {
		padding: 20px;
		margin: 11px 0;
	}

	@include media-breakpoint-down(sm) {
		padding: 15px;
		margin: 9px 0;
	}

	@include media-breakpoint-down(xs) {
		padding: 10px;
		margin: 7px 0;
		border-bottom: 2px solid $grey-lighter;
	}

	&:hover {
		text-decoration: none;
		box-shadow: 0 0 40px rgba(0,0,0,0.05);

		.entry-title, .day, .month-year {
			color: $color-pri;
		}

		time {
			@include media-breakpoint-up(sm) {
				border-right: 3px solid $color-pri;
			}
		}

		&:after {
			right: 10px;
		}
	}

	&:after {
		content: '\f105';
		font-family: 'FontAwesome';
		color: $color-pri;
		font-size: 6rem;
		line-height: .8em;
		position: absolute;
		bottom: 15px;
		right: 20px;
		transition: all 0.4s ease-in-out;

		@include media-breakpoint-down(lg) {
			font-size: 5.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 5rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 3rem;
			right: 10px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 3rem;
		}
	}

	.post-date,
	.post-title,
	.post-excerpt {
		display: block;
		color: $color-pri;
	}

	.post-date {
		@include media-breakpoint-up(sm) {
			border-right: 3px solid $color-pri;
		}
	}

	time {
		display: block;
		transition: all 0.2s ease-in-out;
		border-right: 0 solid transparent;
		text-align: center;
	}

	.day {
		font-weight: bold;
		font-size: 9rem;
		line-height: 9rem;
		display: block;
		transition: all 0.2s ease-in-out;

		@include media-breakpoint-down(lg) {
			font-size: 8rem;
			line-height: 8rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 7rem;
			line-height: 7rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 5rem;
			line-height: 5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 5rem;
			line-height: 5rem;
		}
	}

	.month-year {
		font-size: 2rem;
		line-height: 4rem;
		display: block;
		transition: all 0.2s ease-in-out;

		@include media-breakpoint-down(lg) {
			font-size: 1.9rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.8rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.7rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.6rem;
		}
	}

	.post-title {
		font-weight: bold;
		margin-bottom: 15px;
		font-size: 2.8rem;
		line-height: 1.1em;

		@include media-breakpoint-down(lg) {
			font-size: 2.5rem;
			margin-bottom: 13px;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.2rem;
			margin-bottom: 11px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.9rem;
			margin-bottom: 9px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.6rem;
			margin-bottom: 7px;
		}
	}
}

.single-post {
	@include section-padding;

	.entry-title {
		text-align: center;
		margin: 0;
	}

	.entry-meta {
		text-align: center;

		.updated {
			font-size: 1.5rem;
			color: $color-pri;
		}
	}
}

.single-post,
.page-content {

	.entry-image {
		margin: 40px 0;

		@include media-breakpoint-down(lg) {
			margin: 35px 0;
		}

		@include media-breakpoint-down(md) {
			margin: 30px 0;
		}

		@include media-breakpoint-down(sm) {
			margin: 25px 0;
		}

		@include media-breakpoint-down(xs) {
			margin: 20px 0;
		}
	}

	.entry-content {
		margin: 40px 0 60px;

		@include media-breakpoint-down(lg) {
			margin: 35px 0 50px;
		}

		@include media-breakpoint-down(md) {
			margin: 30px 0 40px;
		}

		@include media-breakpoint-down(sm) {
			margin: 25px 0 30px;
		}

		@include media-breakpoint-down(xs) {
			margin: 20px 0;
		}
	}

	.entry-date,
	.entry-author {
		display: inline-block;
		font-size: 1.7rem;
		line-height: 1em;

		@include media-breakpoint-down(lg) {
			font-size: 1.6rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.4rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.3rem;
		}
	}

	// .entry-date {
	// 	border-right: 2px solid $color-pri;
	// 	margin-right: 15px;
	// 	padding-right: 15px;
	// }

	blockquote {
		color: $color-pri;
		margin: 30px 40px;
		padding-left: 20px;
		border-left: 4px solid $grey-light;

		@include media-breakpoint-down(lg) {
			margin: 25px 35px;
			padding-left: 18px;
		}

		@include media-breakpoint-down(md) {
			margin: 20px 30px;
			padding-left: 16px;
			border-left: 3px solid $grey-light;
		}

		@include media-breakpoint-down(sm) {
			margin: 15px 25px;
			padding-left: 14px;
		}

		@include media-breakpoint-down(xs) {
			margin: 10px 20px;
			padding-left: 12px;
			border-left: 2px solid $grey-light;
		}

		p {
			font-size: 2.4rem;

			@include media-breakpoint-down(lg) {
				font-size: 2.2rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 2rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1.8rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.6rem;
			}
		}

		& + h5 {
			font-family: $font-pri;
			font-weight: 300;
			margin-bottom: 50px;
		}
	}

	img {
		&.size-full {
			margin: 40px 0;

			@include media-breakpoint-down(lg) {
				margin: 35px 0;
			}

			@include media-breakpoint-down(md) {
				margin: 30px 0;
			}

			@include media-breakpoint-down(sm) {
				margin: 25px 0;
			}

			@include media-breakpoint-down(xs) {
				margin: 20px 0;
			}
		}

		&.alignleft {
			margin: 20px 30px 20px 0;

			@include media-breakpoint-down(lg) {
				margin: 18px 27px 18px 0;
			}

			@include media-breakpoint-down(md) {
				margin: 16px 24px 16px 0;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				margin: 25px 0;
			}

			@include media-breakpoint-down(xs) {
				margin: 20px 0;
			}
		}

		&.alignright {
			margin: 20px 0 20px 30px;

			@include media-breakpoint-down(lg) {
				margin: 18px 0 18px 27px;
			}

			@include media-breakpoint-down(md) {
				margin: 16px 0 16px 24px;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				margin: 25px 0;
			}

			@include media-breakpoint-down(xs) {
				margin: 20px 0;
			}
		}
	}

	.entry-tools {
		position: relative;
	}

	.entry-nav {
		width: 100%;

		ul {
			font-size: 0;
		}

		li {
			width: 50%;
			display: inline-block;
		}

		a {
			text-transform: uppercase;
			position: relative;

			&:hover {
				text-decoration: none;
				color: $color-pri;
			}
		}

		.next,
		.prev {

			a {
				color: $color-pri;

				&:after {
					font-family: 'FontAwesome';
					font-size: 4rem;
					color: $color-pri;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					@include media-breakpoint-down(lg) {
						font-size: 3.5rem;
					}

					@include media-breakpoint-down(md) {
						font-size: 3rem;
					}

					@include media-breakpoint-down(sm) {
						font-size: 2.5rem;
					}

					@include media-breakpoint-down(xs) {
						font-size: 2rem;
					}
				}
			}
		}

		.next {
			text-align: right;

			a {
				padding-right: 30px;

				&:after {
					content: '\f105';
					right: 0;
				}
			}
		}

		.prev {
			a {
				padding-left: 30px;

				&:after {
					content: '\f104';
					left: 0;
				}
			}
		}
	}

	.share-btns {
		text-align: center;

		@include media-breakpoint-up(md) {
			position: absolute;
			top: -10px;
			left: 50%;
			transform: translateX(-50%);
		}

		@include media-breakpoint-down(sm) {
			margin-top: 20px;
		}

		a {
			margin: 0 5px;
		}

		.at-share-btn {
			border: 1px solid $color-pri;
			background: transparent !important;
		}

		svg {
			fill: $color-pri!important;
		}
	}
}

















