.block-faq {
  margin: 50px 0;

  @include media-breakpoint-down(lg) {
    margin: 45px 0;
  }

  @include media-breakpoint-down(md) {
    margin: 40px 0;
  }

  @include media-breakpoint-down(sm) {
    margin: 35px 0;
  }

  @include media-breakpoint-down(xs) {
    margin: 30px 0;
  }

  .faq-anchors {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
      margin-bottom: 45px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 35px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
      display: none;
    }
  }

  .anchor {
    background: $color-pri;
    margin: 10px;
    padding: 15px;
    display: inline-block;
    width: 270px;
    position: relative;

    @include media-breakpoint-down(lg) {
      width: 230px;
    }

    @include media-breakpoint-down(md) {
      width: 190px;
    }

    @include media-breakpoint-down(sm) {
      width: 150px;
    }

    @include media-breakpoint-down(xs) {
      width: initial;
    }

    &:hover {
      text-decoration: none;
      background: $black;

      .anchor-title {
        color: white;
        font-weight: bold;
      }

      // .icon {
      //   opacity: 0;
      // }

      // .icon-hover {
      //   opacity: 1;
      // }

      .arrow {
        svg {
          stroke: white;
        }
      }
    }

    span, img {
      display: block;
      transition: .2s all ease-in-out;
    }
  }

  .anchor-icon {
    display: block;
    width: 60px;
    height: 60px;
    transition: .2s all ease-in-out;
    margin-bottom: 50px;
    position: relative;

    @include media-breakpoint-down(lg) {
      width: 55px;
      height: 55px;
      margin-bottom: 45px;
    }

    @include media-breakpoint-down(md) {
      width: 50px;
      height: 50px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
      margin-bottom: 35px;
    }

    @include media-breakpoint-down(xs) {
      width: 30px;
      height: 30px;
      margin-bottom: 30px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    .icon-hover {
      opacity: 0;
    }
  }

  .arrow {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 1;

    svg {
      width: 20px;
      height: 20px;
      stroke: black;
      transition: .2s all ease-in-out;
    }
  }

  .anchor-title {
    color: black;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2em;

    @include media-breakpoint-down(lg) {
      font-size: 1.9rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.6rem;
    }
  }

  .faq-cat-title {
    margin: 2em 0 .5em;
    color: $grey-dark;
    font-size: 3rem;
    font-weight: 300;
    padding: .25em;

    @include media-breakpoint-down(lg) {
      font-size: 2.7rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2.4rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 2.1rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.8rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .faq-cat-title-icon {
    display: inline-block;
    margin-right: 15px;

    img {
      width: 40px;
      height: auto;
      max-width: 40px;
      max-height: 40px;

      @include media-breakpoint-down(lg) {
        max-width: 37px;
        max-height: 37px;
      }

      @include media-breakpoint-down(md) {
        max-width: 34px;
        max-height: 34px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 31px;
        max-height: 31px;
      }

      @include media-breakpoint-down(xs) {
        max-width: 28px;
        max-height: 28px;
      }
    }
  }

  .question {
    color: $grey-dark;
    font-size: 2rem;
    font-weight: 400;
    padding: 1em 5em 1em 2em;
    margin: 0;
    border-top: 2px solid $grey-lighter;
    display: block;
    position: relative;

    @include media-breakpoint-down(lg) {
      font-size: 1.9rem;
      padding: 1em 4.5em 1em 1.7em;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
      padding: 1em 4em 1em 1.4em;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
      padding: 1em 3.5em 1em 1em;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.6rem;
      padding: 1em 3em 1em 0;
    }

    &:hover {
      color: $color-pri;
      box-shadow: 0 10px 40px rgba(0,0,0,0.05);
      text-decoration: none;
    }

    &.active {
      background: $grey-lighter;
      // border-left: 5px solid $color-pri;

      .question-arrow {
        transform: translateY(-50%) rotate(180deg);
      }

      &:hover {
        box-shadow: none;
      }

      // & + .answer {
      //   border-left: 5px solid $color-pri;
      // }
    }

    .question-arrow {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      z-index: 1;

      svg {
        width: 20px;
        height: 20px;
        stroke: $color-pri;
        transition: .2s all ease-in-out;
      }
    }
  }

  .answer {
    padding: 4em;
    padding-top: 0 !important;
    display: none;
    background: $grey-lighter;
    // border-left: 5px solid $color-pri;

    @include media-breakpoint-down(lg) {
      padding: 3.5em;
    }

    @include media-breakpoint-down(md) {
      padding: 3em;
    }

    @include media-breakpoint-down(sm) {
      padding: 2.5em;
    }

    @include media-breakpoint-down(xs) {
      padding: 2em;
    }

    .text {
      margin-bottom: 0;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}






