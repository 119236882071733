.nav-pri {
  @include media-breakpoint-up(lg) {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    display: block !important;
    text-align: center;
  }

  @include media-breakpoint-down(md) {
    display: none;
    margin-top: 20px;
  }

  & > ul {
    font-size: 0;
  }

  & > ul > li {
    list-style: none;
    display: inline-block;
    margin: 0;

    @include media-breakpoint-down(md) {
      width: 100%;
      border-bottom: 1px solid $grey-light;
    }

    &.reveal {
      @include media-breakpoint-down(sm) {
        visibility: visible;
        opacity: 1!important;
      }
    }

    &.active {
      & > a {
        text-decoration: none;
        font-weight: bold;
        color: $color-pri;

        &:hover {
          background: transparent;
          // cursor: default;
        }
      }
    }

    &.menu-item-has-children {
      position: relative;
      cursor: pointer;
      
      & > a {
        // @include media-breakpoint-up(sm) {
        //   padding-bottom: 38px;
        // }

        @include media-breakpoint-down(md) {
          position: relative;

          &:after {
            font-family: 'FontAwesome';
            content: "\f078";
            color: $grey-dark;
            position: absolute;
            top: 11px;
            right: 10px;
          }
        }

        &:hover {
          color: $grey-dark;
        }
      }

      &:hover > ul,
      &.hovered ul {
        @include media-breakpoint-up(md) {
          z-index: 6;
          opacity: 1;
          top: 100%;
          padding: 5px 20px 0;
          display: block;

          & > li > a {
            padding: 10px 5px;
          }
        }

        @include media-breakpoint-down(md) {
          & > li > a {
            padding: 10px;
          }
        }
      }
    }
  }

  & > ul > li > a {
    color: $grey-dark;
    font-size: 1.7rem;
    font-weight: 400;
    padding: 0 15px;
    display: block;

    @include media-breakpoint-down(lg) {
      font-size: 1.4rem;
      padding: 0 10px;
    }

    @include media-breakpoint-down(md) {
      padding: 10px;
    }

    &:hover {
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        color: $color-pri;
      }
    }
  }

  /* second level */
  .sub-menu {
    background: $grey-lighter;
    padding: 5px 20px 0;
    min-width: 250px;

    @include media-breakpoint-up(lg) {
      z-index: -1;
      opacity: 0;
      display: none;
      position: absolute;
      // top: 79px;
      top: 95%;
      left: 50%;
      transition: all 0.2s ease-in-out;
      text-align: center;
      transform: translateX(-50%);
    }

    @include media-breakpoint-down(md) {
      min-width: 190px;
      background: white;
    }

    @include media-breakpoint-down(sm) {
      min-width: 180px;
    }

    @include media-breakpoint-down(xs) {
      min-width: inherit;
    }

    li {
      list-style-type: none;
      border-bottom: 2px solid $grey-light;

      @include media-breakpoint-down(md) {
        border-bottom-width: 1px;
      }

      &:last-child {
        border-bottom: 0;
      }

      &.current-menu-item {
        a {
          color: $color-pri;

          @include media-breakpoint-down(xs) {
            color: $color-pri;
            font-weight: bold;
          }
        }
      }

      &.disabled {
        a {
          color: $grey;
        }
      }
    }

    a {
      color: $grey-dark;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 0;
      display: block;

      @include media-breakpoint-down(lg) {
        font-size: 1.3rem;
      }

      @include media-breakpoint-down(md) {
        padding: 10px;
      }

      &:hover {
        text-decoration: none;

        @include media-breakpoint-up(lg) {
          color: $color-pri;
        }
      }
    }
  }

  .m-book {
    &.active {
      a {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    a {
      position: relative;
      padding-right: 20px;

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        background-image: url('../images/triangle-down.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        z-index: 1;
        transition: .2s all ease-in-out;
      }
    }
  }
}

// .nav-sec {
//   position: absolute;
//   right: 30px;

//   @include media-breakpoint-up(lg) {
//     top: 50%;
//     transform: translateY(-50%);
//   }

//   @include media-breakpoint-down(md) {
//     top: 16px;
//     right: 80px;
//   }

//   @include media-breakpoint-down(sm) {
//     top: 10px;
//   }

//   @include media-breakpoint-down(xs) {
//     display: none;
//   }

//   .fa {
//     font-size: 5rem;
//     color: $grey-dark;
//     vertical-align: middle;
//     margin-right: 10px;
//   }

//   a {
//     color: $grey-dark;
//     font-size: 1.8rem;
//     font-weight: 400;

//     &:hover {
//       color: $color-pri;
//       text-decoration: none;
//     }
//   }
// }

.nav-sec {
  background: $grey-lighter;
  padding: 10px 30px;
  text-align: right;
  // position: absolute;
  // right: 30px;
  // top: 50%;
  // transform: translateY(-50%);

  // @include media-breakpoint-down(md) {
  //   // top: 16px;
  //   right: 80px;
  // }

  // // @include media-breakpoint-down(sm) {
  // //   top: 10px;
  // // }

  @include media-breakpoint-down(xs) {
    padding: 5px 20px;
    text-align: center;
  }

  .fa {
    font-size: 5rem;
    color: $grey-light;
    vertical-align: middle;
    margin-right: 10px;
  }

  .phone {
    display: inline-block;
    text-align: right;

    @include media-breakpoint-down(xs) {
      text-align: center;
    }

    & + .phone {
      margin-left: 15px;
    }

    span {
      color: $color-pri;
      display: block;
      font-weight: bold;
    }
  }

  a {
    color: $grey-dark;
    font-size: 1.6rem;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }

    &:hover {
      color: $color-pri;
      text-decoration: none;
    }
  }
}

.nav-lang {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);

  @include media-breakpoint-down(md) {
    right: 90px;
  }

  @include media-breakpoint-down(xs) {
    right: 60px;
  }

  ul {
    display: inline-block;
  }

  .coming-soon {
    display: inline-block;
    margin-right: 5px;
    color: $grey;
  }

  a {
    color: $grey-dark;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      color: $color-pri;
      text-decoration: none;
    }
  }
}

// Nav toggle
// https://codepen.io/designcouch/pen/Atyop
.nav-toggle {
  width: 23px;
  height: 18px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
    position: absolute;
    top: 41px;
    right: 30px;
  }

  @include media-breakpoint-down(sm) {
    top: 35px;
  }

  @include media-breakpoint-down(xs) {
    top: 30px;
    right: 20px;
  }

  &.open {

    span {
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $grey-dark;
    // border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
      height: 2px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }
}



