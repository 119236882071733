section.banner {
	.carousel {
		z-index: 5;
	}

	.carousel-item {
		height: 600px;

		@include media-breakpoint-down(lg) {
			height: 500px;
		}

		@include media-breakpoint-down(md) {
			height: 400px;
		}

		@include media-breakpoint-down(sm) {
			height: 350px;
		}

		@include media-breakpoint-down(xs) {
			height: 300px;
		}

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			@include gradient-side($color-pri, rgba(0,0,0,0));

			@include media-breakpoint-down(xs) {
				background: $color-pri;
				opacity: .8;
			}
		}
	}

	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		display: block;
	}

	.banner-image {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.container {
		height: 100%;
	}

	.banner-content {
		width: 90%;
		position: relative;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		z-index: 3;

		@include media-breakpoint-down(xs) {
			width: 80%;
			right: initial;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}

	.banner-title,
	.banner-subtitle {
		color: white;
		font-size: 6rem;
		font-weight: 600;

		@include media-breakpoint-down(lg) {
			font-size: 5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 3rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2rem;
		}
	}

	.banner-subtitle {
		display: block;
		font-weight: 300;
	}

	.text {
		margin-top: 20px;
		color: white;
		max-width: 400px;
	}

	.button  {
		margin-top: 30px;

		@include media-breakpoint-down(lg) {
			margin-top: 25px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 20px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 15px;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 10px;
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
	  top: 50%;
	  transform: translateY(-50%);
	  opacity: 1;
	  width: 5%;
	  z-index: 3;

	  @include media-breakpoint-down(lg) {
			width: 7%;
		}

		@include media-breakpoint-down(md) {
			width: 9%;
		}

		@include media-breakpoint-down(sm) {
			width: 10%;
		}

		@include media-breakpoint-down(xs) {
			width: 11%;
		}
	}

	.carousel-control-prev {
		left: 0;
	}

	.carousel-control-next {
		right: 0;
	}

	.carousel-control-prev-icon,
	.carousel-control-next-icon, {
		background-image: none;
	}

	.fa {
		color: white;
		font-size: 5rem;
		margin-top: -3px;
		opacity: .5;

		@include media-breakpoint-down(lg) {
			font-size: 4.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 3rem;
		}

		&.fa-angle-left {
			margin-right: 4px;
		}

		&.fa-angle-right {
			margin-left: 4px;
		}
	}
}

section.services {
	@include section-padding;
	text-align: center;

	.title-wrapper {
		@include hr($color-pri, 'center', 'bottom');
	}

	.services-subhead {
		font-size: 3rem;
		font-weight: 300;
		margin: 0 0 10px;

		@include media-breakpoint-down(lg) {
			font-size: 2.7rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.1rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.8rem;
		}
	}

	.services-title {
		font-size: 3.5rem;
		font-weight: 600;
		margin: 0;

		@include media-breakpoint-down(lg) {
			font-size: 3.2rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.9rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.6rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2.3rem;
		}

		span {
			display: block;
		}
	}

	.services-subtitle {
		font-size: 1.6rem;
		font-weight: 300;
		margin: 10px 0 0;

		@include media-breakpoint-down(lg) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.3rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.2rem;
		}
	}

	.text {
		max-width: 600px;
		margin: 0 auto;
	}

	.services-hub {
		margin-top: 40px;
	}

	.service {
		margin: 15px 0;
	}

	.service-icon {
		margin-bottom: 20px;
		
		img {
			max-width: 80px;
			max-height: 80px;
		}
	}

	.service-title {
		font-size: 4rem;
		font-weight: 300;
		@include hr($color-pri, 'center', 'bottom');

		@include media-breakpoint-down(lg) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 3rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2rem;
		}
	}

	.button {
		margin-top: 60px;

		@include media-breakpoint-down(lg) {
			margin-top: 50px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 40px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 20px;
		}
	}
}













