// Disable native datepicker in Google Chrome
// http://stackoverflow.com/questions/11320615/disable-native-datepicker-in-google-chrome
input::-webkit-calendar-picker-indicator{
  display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}


.input-group-addon {
	background: white;
	border: 1px solid $grey-lighter;
	color: $color-pri;
	font-size: 1.8rem;
	border-radius: 0;
	padding-right: 7px;
}

.bootstrap-datetimepicker-widget {

	&.dropdown-menu {
		width: 310px;
		// padding: 20px 10px;
	}

	.list-unstyled {
		list-style: none;

		li {
			list-style: none;
		}
	}

	.picker-switch {
		font-size: 1.2rem;
		text-transform: uppercase;
		margin-bottom: 10px;

		&.accordion-toggle {
			display: none;
		}
	}

	thead {
		padding-bottom: 10px;
	}

	tbody {
		td {
			padding: 5px;
			font-size: 1.4rem;

			&.today {
				border-radius: 0;
				font-weight: bold;

				&:before {
					display: none;
				}
			}

			&:not(:first-child) {
				border-left: 1px solid $grey-light;
			}

			&.new,
			&.old {
				color: $grey;
			}

			span.active,
			&.active,
			&.active:hover {
				background: $color-pri;
				color: white;
				font-weight: bold;
			}

			&.disabled,
			&.disabled:hover {
				color: $grey-light;
			}
		}
	}

	.dow {
		font-size: 1.2rem;
		// font-weight: bold;
		color: $color-pri;
	}

	.glyphicon-chevron-left,
	.glyphicon-chevron-right {
		&:after {
			font-family: 'FontAwesome';
			font-size: 1.5rem;
			color: black;
		}
	}

	.glyphicon-chevron-left {
		&:after {
			content: '\f053';
		}
	}

	.glyphicon-chevron-right {
		&:after {
			content: '\f054';
		}
	}

	table td.active,
	table td.active:hover,
	table td.day:hover,
	table td.hour:hover,
	table td.minute:hover,
	table td.second:hover,
	table thead tr:first-child th:hover,
	table td,
	table th,
	table td span {
		border-radius: 0;
	}
}

