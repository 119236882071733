header {
	position: relative;
	z-index: 2;
	background: white;
	width: 100%;
	padding: 30px;

	@include media-breakpoint-down(lg) {
		padding: 25px;
	}

	@include media-breakpoint-down(md) {
		padding: 20px;
	}

	@include media-breakpoint-down(sm) {
		padding: 15px;
	}

	@include media-breakpoint-down(xs) {
		padding: 10px;
	}

	&.header-af {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.brand {
		display: inline-block;

		img {
			max-width: 200px;

			@include media-breakpoint-down(lg) {
				max-width: 190px;
			}

			@include media-breakpoint-down(md) {
				max-width: 180px;
			}

			@include media-breakpoint-down(sm) {
				max-width: 170px;
			}

			@include media-breakpoint-down(xs) {
				max-width: 160px;
			}
		}
	}

	.brand-af {
		display: inline-block;

		img {
			width: 100px;

			@include media-breakpoint-down(lg) {
				width: 90px;
			}

			@include media-breakpoint-down(md) {
				width: 80px;
			}

			@include media-breakpoint-down(sm) {
				width: 70px;
			}

			@include media-breakpoint-down(xs) {
				width: 60px;
			}
		}
	}
}













