.footer {
	padding: 20px;
	text-align: center;

	.logo {
		margin-top: 60px;
		display: block;

		@include media-breakpoint-down(lg) {
			margin-top: 55px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 50px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 45px;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 40px;
		}

		img {
			max-width: 200px;

			@include media-breakpoint-down(lg) {
				max-width: 190px;
			}

			@include media-breakpoint-down(md) {
				max-width: 180px;
			}

			@include media-breakpoint-down(sm) {
				max-width: 170px;
			}

			@include media-breakpoint-down(xs) {
				max-width: 160px;
			}
		}
	}

	.division {
		display: inline-block;
		border-top: 1px solid $grey-lighter;
		padding-top: 20px;
		margin: 20px 0;
	}

	.division-text {
		color: $grey;
		font-size: 1.2rem;
		font-weight: 300;
		margin-bottom: 15px;
	}

	.division-logo {
		img {
			max-width: 70px;
		}
	}

	// .tel-number {
	// 	@include hr($color-pri, 'center', 'bottom');
	// }

	.phone {
		font-size: 3rem;
		font-weight: 600;
		line-height: 1em;
		display: block;
		color: $grey-dark;

		@include media-breakpoint-down(lg) {
			font-size: 2.7rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.1rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.8rem;
		}

		&:hover {
			text-decoration: none;
			color: $color-pri;
		}
	}

	.station {
		font-size: 1.3rem;
		font-weight: 300;
		color: $grey;
	}

	.address-link {
		font-size: 1.6rem;
		display: block;
		color: $grey-dark;

		@include media-breakpoint-down(lg) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.3rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.2rem;
		}

		&:hover {
			text-decoration: none;
			color: $color-pri;
		}
	}

	.phone,
	.address-link {
		margin-top: 25px;

		.fa {
			display: block;
			font-size: 5rem;
			color: $color-pri;
			margin-bottom: 20px;

			@include media-breakpoint-down(lg) {
				font-size: 4.5rem;
				margin-bottom: 18px;
			}

			@include media-breakpoint-down(md) {
				font-size: 4rem;
				margin-bottom: 16px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 3.5rem;
				margin-bottom: 14px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 3rem;
				margin-bottom: 12px;
			}
		}
	}

	.socials {
		text-align: center;
		margin: 50px 0;

		.social {
			display: inline-block;
			margin: 0 15px;

			@include media-breakpoint-down(lg) {
				margin: 0 13px;
			}

			@include media-breakpoint-down(md) {
				margin: 0 11px;
			}

			@include media-breakpoint-down(sm) {
				margin: 0 9px;
			}

			@include media-breakpoint-down(xs) {
				margin: 0 5px;
			}

			&:hover {
				.fa {
					color: $grey-dark;
				}
			}
		}

		.fa {
			color: $color-pri;
			font-size: 4rem;
			transition: all 0.2s ease-in-out;

			@include media-breakpoint-down(lg) {
				font-size: 3.8rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 3.6rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 3.4rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 3.2rem;
			}
		}
	}

	.copy {
		text-align: center;

		.text,
		.link {
			font-size: 1.2rem;
			color: $grey;
		}
	}
}

.b-footer {
  background: $color-pri;
  @include section-padding;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  .container {
  	@include media-breakpoint-down(md) {
  		max-width: 100%;
  	}
  }

  .b-logo {
    margin-bottom: 30px;

    img {
      width: 250px;
      max-width: 100%;
    }
  }

  .b-cs {
  	margin-top: 40px;

  	@include media-breakpoint-down(sm) {
  		margin-bottom: 30px;
  	}

    .text {
    	margin-bottom: 0;
    }

    .b-phone {
      color: white;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: white;
      }
    }
  }

  .b-footer-title {
    display: block;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }

  .nav-footer {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      margin-top: 15px;
      list-style-type: none;
      line-height: 1;
    }

    a {
      color: white;
      font-size: 15px;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .nav-policies {
  	margin-top: 20px;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      padding: 0 5px;
      list-style-type: none;
      line-height: 1;

      &:not(:last-child) {
      	border-right: 1px solid rgba(white, .5);
      }
    }

    a {
    	display: block;
      color: white;
      font-size: 1.2rem;
      font-weight: 400;
      text-decoration: none;
      // opacity: .5;
      line-height: 1;

      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }

  .b-socials {
    .b-social {
      width: 45px;
      height: 45px;
      display: inline-block;
      margin: 3px;
      background: transparent;
      border-radius: 50%;
      border: 1px solid white;
      position: relative;

      &:hover {
        background: white;
        border-color: white;

        i {
          color: $color-pri;
        }
      }
    }

    i {
      color: white;
      font-size: 2rem;
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
    }
  }

  .b-copyrights {
    opacity: .5;
    margin-top: 60px;

    @include media-breakpoint-down(sm) {
    	margin-top: 30px;
    }
  }
}















