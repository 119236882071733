.page-header {
	position: relative;
	text-align: center;
	@include section-padding;

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: $color-pri;
		opacity: .6;
	}

	.page-header-bg {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}

	.page-header-content {
		position: relative;
		z-index: 2;
	}

	.page-header-title {
		color: white;
		font-size: 5rem;
		font-weight: 300;

		@include media-breakpoint-down(lg) {
			font-size: 4.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 3rem;
		}
	}
}

section.cta {
	@include section-padding;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background: $color-pri;
		opacity: .8;
	}

	.cta-subtitle {
		color: white;
		font-size: 1.8rem;
		// text-transform: uppercase;
		margin: 0;

		@include media-breakpoint-down(lg) {
			font-size: 1.7rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.6rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.4rem;
		}
	}

	.cta-title {
		color: white;
		font-size: 6rem;
		font-weight: 600;
		// text-transform: uppercase;
		margin: 20px 0;
		padding: 10px 0;
		border-top: 1px solid white;
		border-bottom: 1px solid white;

		@include media-breakpoint-down(lg) {
			font-size: 5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 3rem;
		}
	}

	.text,
	.text p {
		color: white;
		font-size: 1.4rem;
		margin: 0;
	}

	.button-wrapper {
		text-align: center;

		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}
	}
}

.services-page {
	section.services {
		padding: 0;
	}

	.service-title {
		margin-top: 0!important;
	}
}


.page-blog,
.page-content,
.charter-page,
.fleet-page {
	@include section-padding;
}

.content-centered {
	@include section-padding;
	text-align: center;

	h2 {
		font-size: 4rem;
		font-weight: 300;
		@include hr($color-pri, 'center', 'bottom');

		@include media-breakpoint-down(lg) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 3rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2rem;
		}

		&:not(:first-child) {
			margin-top: 2em;
		}
	}
}

.content-image {
	width: 100%;
	height: 60vh;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@include media-breakpoint-down(lg) {
		height: 400px;
	}

	@include media-breakpoint-down(md) {
		height: 350px;
	}

	@include media-breakpoint-down(sm) {
		height: 300px;
	}

	@include media-breakpoint-down(xs) {
		height: 250px;
	}
}

.fleet-single {
	.content {
		@include section-padding;
	}

	.fleet-title {
		font-size: 4rem;
		font-weight: 300;
		@include hr($color-pri, 'left', 'bottom');

		@include media-breakpoint-down(lg) {
			font-size: 3.5rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 3rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2rem;
		}
	}

	.image-side {
		@include media-breakpoint-down(sm) {
			margin: 30px;
		}
	}

	.text,
	.text p {
		letter-spacing: 1px;
		line-height: 2em;
	}

	.button {
		margin-top: 60px;
		display: table;

		@include media-breakpoint-down(lg) {
			margin-top: 50px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 40px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 20px;
		}

		& + .button {
			margin-top: 30px;
		}
	}

	.image-content,
	.text-content {
		margin: 60px 0;

		@include media-breakpoint-down(lg) {
			margin: 50px 0;
		}

		@include media-breakpoint-down(md) {
			margin: 40px 0;
		}

		@include media-breakpoint-down(sm) {
			margin: 30px 0;
		}

		@include media-breakpoint-down(xs) {
			margin: 20px 0;
		}
	}

	.image-config {
		text-align: center;
		margin-top: 75px;

		@include media-breakpoint-down(lg) {
			margin-top: 60px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 50px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 40px;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 30px;
		}

		img {
			max-width: 500px;

			@include media-breakpoint-down(sm) {
				max-width: 100%;
			}
		}
	}

	.quote {
		text-align: center;
		
		blockquote {
			font-size: 2rem;
			margin: 30px 0;

			@include media-breakpoint-down(lg) {
				font-size: 1.9rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 1.8rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1.7rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.6rem;
			}
		}

		.quote-signature {
			color: $color-pri;
			font-size: 2rem;
			font-weight: 600;

			@include media-breakpoint-down(lg) {
				font-size: 1.9rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 1.8rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1.7rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.6rem;
			}
		}
		
		.quote-position {
			font-size: 1.2rem;
			font-weight: 300;
		}
	}

	.content {
		h2 {
			font-size: 4rem;
			font-weight: 300;
			@include hr($color-pri, 'left', 'bottom');

			@include media-breakpoint-down(lg) {
				font-size: 3.5rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 3rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 2.5rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 2rem;
			}
		}

		ul {
			margin: 40px 0;

			// strong {
			// 	color: $color-pri;
			// 	font-weight: 600;
			// }
		}

		p {
			& + ul {
				margin-top: 0;
			}
		}
	}
}

.stats {
	@include section-padding;
	text-align: center;
	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: $color-pri;
		opacity: .6;
	}

	.stats-bg {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

		@include media-breakpoint-up(lg) {
			background-attachment: fixed;
		}
	}

	.stat {
		position: relative;
		z-index: 2;
		@include media-breakpoint-down(sm) {
			margin: 20px 0;
		}

		@include media-breakpoint-down(sm) {
			margin: 20px;
		}
	}

	.stats-title {
		font-size: 2.5rem;
		color: white;
		border-bottom: 1px solid white;
		padding-bottom: 10px;
		margin-bottom: 30px;
		text-transform: uppercase;

		@include media-breakpoint-down(lg) {
			font-size: 2.3rem;
			margin-bottom: 25px;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.1rem;
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.9rem;
			margin-bottom: 15px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.7rem;
			margin-bottom: 10px;
		}
	}

	.stats-number {
		font-size: 4.8rem;
		color: white;
		margin: 0;
		vertical-align: middle;
		display: inline-block;

		@include media-breakpoint-down(lg) {
			font-size: 4.6rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 4.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 4.2rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 4rem;
		}
	}

	.stats-units {
		font-family: $font-pri;
		font-size: 1.8rem;
		font-weight: 400;
		color: white;
		margin: 0;
		vertical-align: middle;
		display: inline-block;

		@include media-breakpoint-down(lg) {
			font-size: 1.7rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.6rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.4rem;
		}
	}

	.stats-text {
		font-size: 1.8rem;
		color: white;
		margin: 20px 0 0;

		@include media-breakpoint-down(lg) {
			font-size: 1.7rem;
			margin: 17px 0 0;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.6rem;
			margin: 14px 0 0;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
			margin: 0;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.4rem;
			margin: 8px 0 0;
		}
	}
}

.fleet-page {
	.fleet-post {
		background: $grey-lighter;
		padding: 30px;
		margin: 15px 0;
		text-align: center;
		display: block;

		&:hover {
			text-decoration: none;

			img {
				transform: scale(.95);
			}

			.entry-title {
				color: $color-pri;
			}
		}
	}

	.entry-title {
		color: $color-pri;
		transition: all 0.2s ease-in-out;
		font-size: 2rem;

		@include media-breakpoint-down(lg) {
			font-size: 1.9rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.8rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.7rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.6rem;
		}
	}

	img {
		transition: all 0.2s ease-in-out;
	}
}

.contact-page {
	@include section-padding;

	.tel-number {
		@include hr($color-pri, 'center', 'bottom');
		text-align: center;
	}

	.phone {
		font-size: 3rem;
		font-weight: 600;
		line-height: 1em;
		display: block;
		margin-top: 15px;
		color: $grey-dark;

		@include media-breakpoint-down(lg) {
			font-size: 2.7rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.1rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.8rem;
		}

		&:hover {
			text-decoration: none;
			color: $color-pri;
		}

		.fa {
			display: block;
			font-size: 8rem;
			color: $color-pri;

			@include media-breakpoint-down(lg) {
				font-size: 7rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 6rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 5rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 4rem;
			}
		}
	}

	.contact-content {
		margin-top: 50px;
	}

	.address {
		margin: 30px 0;
	}

	.address-subtitle,
	.address-link {
		color: $color-pri;
		font-size: 1.4rem;
		font-weight: 300;
		margin: 0;

		@include media-breakpoint-down(lg) {
			font-size: 1.3rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.2rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.1rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1rem;
		}
	}

	.address-title {
		color: $grey-dark;
		font-size: 2.4rem;
		@include hr($color-pri, 'left', 'bottom');
		margin-top: 10px;
		margin-bottom: 20px;
		padding-bottom: 20px;

		@include media-breakpoint-down(lg) {
			font-size: 2.2rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.8rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.6rem;
		}
	}

	.address-link,
	.address-phone,
	.address-website {
		color: $grey-dark;
		display: block;

		&:hover {
			color: $color-pri;
			text-decoration: none;
		}
	}

	.address-link {
		margin-bottom: 2em;
	}

	.address-phone,
	.address-website {
		font-weight: 600;
	}
}

.quote-page {
	.button {
		margin-top: 30px;
	}

	.gform_wrapper {
		display: block !important;
	}
}

// Instagram plugin
#sbi_load {
	padding: 0!important;
}

.instagram-feed {
	position: relative;

	.button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 3;
	}
}

.not-found-page {
	@include section-padding;
	text-align: center;

	.title {
		font-size: 12rem;

		@include media-breakpoint-down(lg) {
			font-size: 11rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 10rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 9rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 8rem;
		}
	}
}


.faq-page {
	.intro {
		text-align: center;
	}
}


.wysiwyg-page {
	.intro {
		text-align: center;
	}

	.block-faq {
  	margin-bottom: 0;

  	.question {
  		border-top: 0;
  		border-bottom: 2px solid $grey-lighter;
  	}
  }
}

.sections {
	.section {
		margin: 50px 0 0;

		.wysiwyg {
			margin: 50px;

			@include media-breakpoint-down(lg) {
	      margin: 40px;
	    }

	    @include media-breakpoint-down(md) {
	      margin: 30px;
	    }

	    @include media-breakpoint-down(sm) {
	      margin: 20px;
	    }

	    @include media-breakpoint-down(xs) {
	      margin: 20px 0;
	    }
		}
	}

	.section-title {
    background: $grey-lighter;
    border-left: 2px solid $color-pri;
    color: $grey-dark;
    font-size: 2rem;
    font-weight: bold;
    padding: .5em;
    margin: 0;

    @include media-breakpoint-down(lg) {
      font-size: 1.9rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.6rem;
    }
  }

  .section-title-icon {
    display: inline-block;
    margin-right: 15px;

    img {
      width: 40px;

      @include media-breakpoint-down(lg) {
        width: 37px;
      }

      @include media-breakpoint-down(md) {
        width: 34px;
      }

      @include media-breakpoint-down(sm) {
        width: 31px;
      }

      @include media-breakpoint-down(xs) {
        width: 28px;
      }
    }
  }
}



.menu-booking {
	width: 100%;
	border-top: 1px solid $grey-lighter;
	background: white;
	position: fixed;
	top: 116px;
	left: 0;
	z-index: 8;
	padding: 50px 0;
	display: none;
	box-shadow: 0 10px 30px rgba(0,0,0,.05);

	@include media-breakpoint-down(lg) {
		top: 103px;
	}

	@include media-breakpoint-down(md) {
		top: 132px;
	}

	@include media-breakpoint-down(sm) {
		top: 124px;
	}

	@include media-breakpoint-down(xs) {
		top: 115px;
	}

	.container {
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}

	.booking-link {
		width: calc(100% - 30px);
		height: 330px;
		display: block;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;

		@include media-breakpoint-down(lg) {
			height: 310px;
		}

		@include media-breakpoint-down(md) {
			height: 290px;
		}

		@include media-breakpoint-down(sm) {
			height: 270px;
		}

		@include media-breakpoint-down(xs) {
			height: 250px;
		}

		&:hover {
			text-decoration: none;

			.booking-content {
				&:after {
					width: 150px;
				}
			}

			.menu-arrow {
				opacity: 1;
				right: 60px;

				@include media-breakpoint-down(xs) {
					display: none;
				}
			}
		}

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background: $color-pri;
			opacity: .6;
		}
	}

	.menu-arrow {
		position: absolute;
		bottom: 60px;
		right: 80px;
		z-index: 3;
		transition: .2s all ease-in-out;
		opacity: 0;

		svg {
			stroke: white;
		}
	}

	.booking-content {
		position: absolute;
		bottom: 40px;
		left: 40px;
		z-index: 1;
		padding-bottom: 25px;

		&:after {
			content: '';
			width: 0;
			height: 1px;
			background: white;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 0;
			transition: .2s all ease-in-out;
		}
	}

	.booking-link-subhead {
		color: white;
		font-size: 4rem;
		font-weight: 300;
		display: block;
		line-height: 1.1em;
	}

	.booking-link-title {
		color: white;
		font-size: 5.5rem;
		font-weight: bold;
		display: block;
		line-height: 1.1em;
	}

	.nav-booking-wrap {
		border-left: 1px solid $grey-lighter;
		padding: 50px;

		@include media-breakpoint-down(lg) {
      padding: 45px;
    }

    @include media-breakpoint-down(md) {
      padding: 40px;
    }

    @include media-breakpoint-down(sm) {
      padding: 35px;
    }

    @include media-breakpoint-down(xs) {
      padding: 30px;
    }
	}

	.nav-booking-title {
		color: $grey-dark;
		font-size: 2rem;
		font-weight: bold;
	}

	.nav-booking {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      margin-top: 15px;
      list-style-type: none;
      line-height: 1;
    }

    a {
      color: $grey-dark;
      font-size: 1.5rem;
      font-weight: 400;
      text-decoration: none;

      &:hover {
      	color: $color-pri;
      }
    }
  }
}





























