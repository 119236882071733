.store-locator {
  width: 100%;
  position: relative;

  #wpsl-wrap {
    width: initial;
    position: absolute;
    top: 20px;
    left: 70px;
    z-index: 2;
    margin-bottom: initial;
  }

  .title-wrap {
    text-align: center;
  }

  .store-locator-search {
    margin: 0;
    padding: 0;
    background: transparent;
    text-align: center;
  }

  .store-locator-input,
  .store-locator-filter {
    width: 200px;
    max-width: 100%;
    display: inline-block;
    margin: 10px 0;
    vertical-align: middle;

    @include media-breakpoint-down(lg) {
      width: 180px;
    }

    @include media-breakpoint-down(md) {
      width: 160px;
    }

    @include media-breakpoint-down(sm) {
      width: 140px;
    }

    @include media-breakpoint-down(xs) {
      width: 120px;
    }
  }

  .store-locator-button {
    display: inline-block;
    margin: 10px 0;
    vertical-align: middle;

    // @include media-breakpoint-down(sm) {
    //   display: block;
    // }

    @include media-breakpoint-down(xs) {
      margin: 10px 0 0;
    }

    i {
      color: black;

      @include media-breakpoint-down(lg) {
        font-size: 3rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2.8rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 2.6rem;
      }

      @include media-breakpoint-down(xs) {
        font-size: 2.4rem;
      }
    }
  }

  #wpsl-search-input {
    font-family: $font-pri;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1em;
    color: black;
    background: transparent;
    width: 100% !important;
    padding: 5px;
    height: 40px;
    outline: none;
    transition: all 0.2s ease-in-out;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    display: block;
    margin: 0;

    &:focus {
      // box-shadow: 0 0 1px $grey-dark;
      transition: none;
      border-color: black;
    }

    @include placeholder {
      font-weight: 400;
      color: black; // Must be same as input because of select
      // text-transform: uppercase;
    }
  }

  .store-locator-toggle {
    background: transparent;
    width: 55px;
    height: 55px;
    color: white;
    font-size: 3.5rem;
    border: 0;
    transition: .2s all ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  #wpsl-category {
    margin: 0;

    label {
      display: none;
    }
  }

  .wpsl-dropdown {
    width: initial;
    height: 55px;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    float: initial;
    position: relative;
    z-index: 8;
    cursor: pointer;
    vertical-align: middle;
    // background: $grey-darker;

    &:hover {
      box-shadow: none;
    }

    // span, div {
    //  display: none;
    // }

    div {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 8;
      background: white;
    }

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('../images/arrows-v-light.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 9;
      transform: translateY(-50%);
      cursor: pointer;
      pointer-events: none;
    }

    select {
      position: relative;
      z-index: 2;
      appearance: none;
      background: transparent;
      padding-right: 30px;
      cursor: pointer;
      height: auto;
      display: block;
    }

    label {
      display: none;
    }

    // #wpsl-category-list {
    //   display: block !important;
    //   appearance: none !important;
    // }

    li {
      text-align: left;
    }
  }

  .wpsl-selected-item {
    text-align: left;
    font-family: $font-pri;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1em;
    color: black;
    padding-top: 18px !important
  }

  .wpsl-active .wpsl-selected-item:after,
  .wpsl-selected-item:after {
    display: none !important;
  }

  #wpsl-search-btn {
    background: transparent;
    color: white;
    border: 0;
    border-radius: 0;
    float: initial;
    margin: 0;
    box-shadow: none;
    line-height: 0;
    padding: 0;
  }

  #wpsl-gmap {
    width: 100%;
    height: 400px;
    float: initial;
    margin: 0;

    p {
      font-size: 1.5rem;
    }

    .wpsl-directions {
      color: $color-pri;
    }
  }
}











