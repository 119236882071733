button,
a.button,
.button,
.btn,
input[type="submit"],
.post-button a,
.gform_button {
	background: transparent;
	color: $color-pri;
	display: inline-block;
	font-weight: bold;
	position: relative;
	font-size: 1.6rem;
	padding: 10px 15px;

	transition: all 0.2s ease-in-out;
	cursor: pointer;
	border-radius: 3px;
	border: 1px solid $color-pri;

	@include media-breakpoint-down(lg) {
		font-size: 1.5rem;
		padding: 9px 14px;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.4rem;
		padding: 8px 13px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.3rem;
		padding: 7px 12px;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.2rem;
		padding: 6px 11px;
	}

	&:hover {
		text-decoration: none;
		background: $color-pri;
		color: white;
	}

	&.button-black {
		background: black;
		border-color: transparent;

		&:hover {
			text-decoration: none;
			color: $color-pri;
		}
	}

	&.button-white {
		color: $color-pri;
		background: white;
		border-color: transparent;

		&:hover {
			text-decoration: none;
			color: black;
			background: $grey-lighter;
		}
	}

	&.button-arrow {
		background: transparent;
		padding: 0;
		color: $color-pri;
		font-size: 1.3rem;

		&:after {
			content: '\f0da';
			font-family: 'FontAwesome';
			color: $color-pri;
			margin-left: 10px;
			display: inline-block;
			transition: all 0.2s ease-in-out;
		}

		&:hover {
			text-shadow: none;

			&:after {
				margin-left: 15px;
			}
		}

		.fa {
			font-size: 1.3em;
			margin-right: 10px;
		}
	}
}








